import { IconProps } from './iconTypes'

export default function PersonIcon({
  className = 'size-[24px]',
  fill = '#8A8A8A',
  onClick,
}: IconProps) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`size-4 ${className}`}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_2103_52554)">
        <path
          d="M7.99996 7.99984C9.47329 7.99984 10.6666 6.8065 10.6666 5.33317C10.6666 3.85984 9.47329 2.6665 7.99996 2.6665C6.52663 2.6665 5.33329 3.85984 5.33329 5.33317C5.33329 6.8065 6.52663 7.99984 7.99996 7.99984ZM7.99996 9.33317C6.21996 9.33317 2.66663 10.2265 2.66663 11.9998V12.6665C2.66663 13.0332 2.96663 13.3332 3.33329 13.3332H12.6666C13.0333 13.3332 13.3333 13.0332 13.3333 12.6665V11.9998C13.3333 10.2265 9.77996 9.33317 7.99996 9.33317Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}
