import { IconProps } from './iconTypes'

export default function ChatBubbleIcon({
  className = 'size-[24px]',
  fill = '#8A8A8A',
  onClick,
}: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2Z"
        fill={fill}
      />
    </svg>
  )
}
