import { IconProps } from './iconTypes'

export default function GiftBoxIcon({
  className = 'size-[24px]',
  fill = '#8A8A8A',
  onClick,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill={fill}
      className={`size-4 ${className}`}
      onClick={onClick}
    >
      <path d="M168-96v-432H96v-216h184q-5-11-7.5-22.94Q270-778.87 270-792q0-50 35-85t85-35q26.29 0 49.14 11Q462-890 480-871q17-19 40.5-30t49.5-11q50 0 85 35t35 85q0 12-3.5 24t-6.5 24h184v216h-72v432zm402-744q-20.4 0-34.2 13.8T522-792t13.8 34.2T570-744t34.2-13.8T618-792t-13.8-34.2T570-840m-228 48q0 20.4 13.8 34.2T390-744t34.2-13.8T438-792t-13.8-34.2T390-840t-34.2 13.8T342-792M168-672v72h276v-72zm276 504v-360H240v360zm72 0h204v-360H516zm276-432v-72H516v72z" />
    </svg>
  )
}
