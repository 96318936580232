import { MouseEventHandler } from 'react'
import Link from 'next/link'
import UserIconBG from './userIconBG.png'
import Image from 'next/image'

import { useUser } from '../../../lib/auth/UserContext'
import Button from 'components/common/input/Button'
import CloseIcon from '../icons/Close'
import PersonIcon from '../icons/Person'
import { IconProps } from '../icons/iconTypes'
import GiftBoxIcon from '../icons/GiftBox'
import ListIcon from '../icons/List'
import ChatBubbleIcon from '../icons/ChatBubble'
import HelpIcon from '../icons/Help'
import LogoutIcon from '../icons/Logout'

export default function Sidebar({
  onClose,
}: {
  onClose: MouseEventHandler<SVGSVGElement>
}) {
  const { user } = useUser()

  const entry = (
    title: string,
    url: string,
    Icon: React.ComponentType<IconProps>,
  ) => (
    <Link
      href={url}
      passHref
      className={`w-full cursor-pointer text-black no-underline`}
    >
      <div
        className={`flex w-full cursor-pointer flex-row items-center gap-[16px] align-middle`}
      >
        <Icon className={'size-[24px]'} fill="#8A8A8A" />
        <span className="text-[24px]">{title}</span>
      </div>
    </Link>
  )

  return (
    <div className="flex w-[100vw] flex-col items-start gap-[24px] p-[40px] sm:w-[400px]">
      <CloseIcon
        onClick={onClose}
        className="size-[32px] cursor-pointer self-end"
        fill="#323232"
      />
      {user ? (
        <div className="flex flex-row items-center gap-[12px]">
          <div className="relative flex h-[48px] w-[48px] flex-shrink-0 cursor-pointer flex-row items-center justify-center text-center">
            <Image
              src={UserIconBG}
              width={48}
              height={48}
              alt={'User icon'}
              className="absolute left-0 top-0 rounded-full "
            />
            <span className="z-10 text-[24px] font-normal text-white">
              {`${user.names.givenName.charAt(0)}${user.names.familyName.charAt(0)}`.toUpperCase()}
            </span>
          </div>
          <span className="text-[24px]">{`${user.names.givenName} ${user.names.familyName}`}</span>
        </div>
      ) : (
        <div className="w-full">
          <Button className="flex w-full justify-center" primary href="/login">
            Login
          </Button>
        </div>
      )}
      <hr className="w-full border-[#C1C1C1]" />
      {entry('Account', '/account', PersonIcon)}
      {entry('My gifts', '/gifts', GiftBoxIcon)}
      <hr className="w-full border-[#C1C1C1]" />
      {entry('Catalog', '/shop-gift-cards', ListIcon)}
      {entry('Blog', '/blog', ChatBubbleIcon)}
      {entry('FAQ', '/faq', HelpIcon)}
      {user && (
        <>
          <hr className="w-full border-[#C1C1C1]" />
          {entry('Log out', '/logout', LogoutIcon)}
        </>
      )}
    </div>
  )
}
